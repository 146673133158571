<script lang="ts" setup>
import { AisPagination } from "vue-instantsearch/vue3/es";

const { t } = useI18n();
</script>

<template>
  <div class="flex items-center justify-center">
    <AisPagination>
      <template #default="{ currentRefinement, pages, refine, nbPages }">
        <div class="flex flex-row mb-5">
          <div
            v-if="currentRefinement > 0"
            class="h-[40px] flex items-center justify-center mx-5 border border-black cursor-pointer min-w-[150px] grow hover:bg-brand-primary hover:text-white noSelect"
            @click="
              refine(currentRefinement - 1);
              useScrollElementToView('productListContainer');
            "
          >
            {{ t("previous") }}
          </div>
          <div
            v-if="currentRefinement < nbPages - 1"
            class="h-[40px] flex items-center justify-center mx-5 border border-black cursor-pointer min-w-[150px] grow hover:bg-brand-primary hover:text-white noSelect"
            @click="
              refine(currentRefinement + 1);
              useScrollElementToView('productListContainer');
            "
          >
            {{ t("next") }}
          </div>
        </div>
        <nav>
          <div
            v-if="nbPages > 1"
            class="flex items-center -space-x-px h-8 text-sm justify-center"
          >
            <!-- First page -->
            <ProductListPageNumber
              :is-current="currentRefinement === 0"
              :is-first="true"
              :label="t('first_page')"
              @page-click="refine(0)"
            >
              <span class="sr-only">{{ t("first_page") }}</span>
              1
            </ProductListPageNumber>

            <!-- Current page and 3 previous/next -->
            <template v-for="(page, index) in pages" :key="page">
              <div v-if="page > 1 && index === 0">...</div>
              <ProductListPageNumber
                v-if="page > 0 && page < nbPages - 1"
                :show-separator="index < pages.length - 1"
                :is-current="currentRefinement === page"
                :label="`${page + 1}`"
                @page-click="refine(page)"
              >
                {{ page + 1 }}
              </ProductListPageNumber>
              <div v-if="page < nbPages - 2 && index === 6">...</div>
            </template>

            <!-- Last page -->
            <ProductListPageNumber
              :is-current="currentRefinement === nbPages - 1"
              :is-last="true"
              :label="t('last_page')"
              @page-click="refine(nbPages - 1)"
            >
              <span class="sr-only">{{ t("last_page") }}</span>
              {{ nbPages }}
            </ProductListPageNumber>
          </div>
        </nav>
      </template>
    </AisPagination>
  </div>
</template>
