<script lang="ts" setup>
// -----------------------
// props & emits
// -----------------------
defineProps({
  content: {
    type: String,
    required: true,
  },
});

// -----------------------
// refs
// -----------------------
const isOpen = ref(false);
const contentContainer = ref();
const height = ref(0);

// -----------------------
// vue events
// -----------------------
onMounted(() => {
  const fn = setInterval(() => {
    if (contentContainer.value instanceof Element) {
      const elements = contentContainer.value.querySelectorAll("*");

      if (elements.length > 0) {
        const firstElement = elements[0];
        const style = getComputedStyle(firstElement);
        const lineHeight = parseInt(style.lineHeight);
        const marginTop = parseInt(style.marginTop);
        const paddingTop = parseInt(style.paddingTop);

        height.value = lineHeight + marginTop + paddingTop;
      } else {
        height.value = -1;
        clearInterval(fn);
      }
    }
  }, 50);
});
</script>

<template>
  <div
    v-if="height !== -1"
    class="collapsible-block relative border-t border-transparent"
    :class="{ 'overflow-hidden': !isOpen }"
    :style="{ height: isOpen ? null : `${height}px` }"
    @click="isOpen = !isOpen"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div ref="contentContainer" class="pr-[26px]" v-html="content"></div>
    <!-- eslint-enable -->
    <ProductListFilterArrow class="absolute top-0 right-0" :inverted="isOpen" />
  </div>
</template>

<style lang="scss">
.collapsible-block {
  h1,
  h2,
  h3 {
    margin-top: 0 !important;
  }
}
</style>
