<script lang="ts" setup>
/*
  Todo:
  - why we need the filters.lenght <ProductListSearchProvider v-if="filters.length > 0" :sorting="sortingRef"> here? It breaks if we remove that condition with the isIOS() but this way in every place we use this we need to make sure pass something as filter, like "stock > 0", eg the search.vue
*/
import { AisPagination } from "vue-instantsearch/vue3/es";
import { viewItemList as sendViewItemListEvent } from "~/composables/useGoogleAnalytics";
import type { MasterProduct } from "~/types/common";
// const sortingOptions = [];

// -----------------------
// composables
// -----------------------
const config = useRuntimeConfig();
const route = useRoute();
const { isRealContent, isIOS } = useHelpers();
const { status: authStatus, data: authData } = useAuth();
const { t } = useI18n();

// Include Satellite theme.
// Fixme: we only use it now for hierarchical filter, should remove after making our own version of that.
useHead({
  style: [
    {
      href: "https://cdn.jsdelivr.net/npm/instantsearch.css@8.0.0/themes/satellite-min.css",
      integrity: "sha256-p/rGN4RGy6EDumyxF9t7LKxWGg6/MZfGhJM/asKkqvA=",
      crossorigin: "anonymous",
    },
  ],
});

const getUrlHash = computed(() => {
  const urlObject = new URL(window.location.href);
  return btoa(urlObject.toString());
});

// -----------------------
// reactive properties
// -----------------------
const themeGroup = config.public.theme.group;
const hasResults = ref(false);
const searchResults = ref();
let scrollElement, scrollMargin;

if (import.meta.client) {
  scrollElement = useLocalStorage("PLscrollElement-" + getUrlHash.value, "");
  scrollMargin = useLocalStorage("PLscrollMargin-" + getUrlHash.value, 0);
}

const props = defineProps<{
  filters?: string;
  showOutOfStock?: boolean;
  query?: any;
  hideFacets?: Array<string> | null;
  title: string;
  beforeContent?: string | null;
  afterContent?: string | null;
}>();

const showFiltersOnMobile = ref(false);
const hitsNumber = ref(null);
const sortingRef = ref(route.query?.sorting ?? "sku:desc");
const filterContainerClasses = computed(() => {
  if (
    props.beforeContent &&
    props.afterContent &&
    props.beforeContent.length > 0 &&
    props.afterContent.length > 0
  ) {
    return "row-span-3";
  } else if (
    (props.beforeContent && props.beforeContent.length > 0) ||
    (props.afterContent && props.afterContent.length > 0)
  ) {
    return "row-span-2";
  }

  return "";
});

const extendedFilters = computed(() => {
  const parts = [];

  if (!props.showOutOfStock) {
    parts.push("stock > 0");
  }

  if (props.filters && props.filters.length > 0) {
    parts.push(props.filters);
  }

  return parts.join(" AND ");
});

const changeSorting = (newSorting: string) => {
  sortingRef.value = newSorting;
};

const handleSearchResultsUpdate = (products: MasterProduct[]) => {
  sendViewItemListEvent(products, { listName: props.title });
};

const setHitsNumber = (hitsNr: number) => {
  hitsNumber.value = hitsNr;
};

const priceFieldToUse = computed(() => {
  if (authStatus.value === "authenticated") {
    if (
      Array.isArray(authData?.value?.usergroups) &&
      authData?.value?.usergroups?.includes(1)
    ) {
      return "h";
    }
  }

  return "c";
});

const updateScrollY = () => {
  const productCards = document.body.querySelectorAll(
    ".results [id^=product-]",
  );

  for (const productCard of productCards) {
    const boundingClientRect = productCard.getBoundingClientRect();

    if (boundingClientRect.top > 56 /* $menu-height */) {
      scrollElement.value = productCard.id;
      scrollMargin.value = boundingClientRect.top;
      break;
    }
  }
};

if (import.meta.client) {
  onUnmounted(() => {
    window.removeEventListener("scroll", updateScrollY);
  });

  if (scrollElement.value) {
    const restoreScrollStarted = Date.now();
    const restoreScrollFn = setInterval(() => {
      const productCardsContainer =
        searchResults.value?.$el ?? document.body.querySelector(".results");

      if (productCardsContainer instanceof HTMLElement) {
        const productCard = productCardsContainer.querySelector(
          `[id='${scrollElement.value}']`,
        );

        if (productCard) {
          productCard.style.scrollMarginTop = `${scrollMargin.value}px`;
          productCard.scrollIntoView({
            behavior: "instant",
          });

          setTimeout(() => {
            productCard.style.scrollMarginTop = "";
            window.addEventListener("scroll", updateScrollY);
          }, 1000);

          clearInterval(restoreScrollFn);
        }
      }

      if (Date.now() - restoreScrollStarted > 5000) {
        window.addEventListener("scroll", updateScrollY);
        clearInterval(restoreScrollFn);
      }
    }, 50);
  } else {
    window.addEventListener("scroll", updateScrollY);
  }
}
</script>

<template>
  <div v-show="hasResults">
    <div class="flex flex-row items-center mb-3 px-20">
      <h1
        v-if="title && title.length > 0"
        class="!text-3xl font-bold leading-9 font-lora"
        style="margin-top: 0 !important"
      >
        {{ title }}
      </h1>

      <span class="mt-[7px] ml-3 text-nowrap">
        <BaseText v-if="hitsNumber" size="m" class="">
          ({{ t("product_list.hits", { hits: hitsNumber }) }})
        </BaseText>
      </span>
    </div>

    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="isRealContent(beforeContent)"
      class="mb-5 hidden md:block px-20"
      v-html="beforeContent"
    ></div>
    <!--eslint-enable-->

    <ProductListSearchProvider v-if="filters.length > 0" :sorting="sortingRef">
      <ProductListConfigure :filters="extendedFilters" :query="query" />
      <!-- Early-initialization needed in order to restore pagination state -->
      <AisPagination class="hidden"></AisPagination>
      <div v-show="hasResults" id="productListContainer" class="px-20">
        <div class="flex flex-row items-center mb-3">
          <span class="mt-[7px] ml-3 text-nowrap"
            ><ProductListSearchStats @hits-number-update="setHitsNumber"
          /></span>
        </div>

        <BaseCollapsibleBlock
          v-if="isRealContent(beforeContent)"
          :content="beforeContent"
          class="md:hidden mb-[2px]"
        ></BaseCollapsibleBlock>

        <div class="flex mb-5">
          <ProductListSearchBar class="hidden" />
          <ProductListCurrentRefinements />
        </div>

        <div class="mb-5 p-20 rounded-16 bg-brand-surface">
          <div class="grid grid-cols-12 gap-20">
            <div
              class="col-span-12 md:col-span-3 xl:col-span-2"
              :class="filterContainerClasses"
            >
              <div
                id="productListFilters"
                class="filters md:block max-md:p-[25px] max-md:fixed max-md:overflow-y-scroll max-md:left-0 max-md:bg-brand-surface max-md:w-full mt-0"
                :class="{ hidden: !showFiltersOnMobile, ios: isIOS() }"
              >
                <div class="flex justify-between md:hidden mb-[2px]">
                  <span class="">
                    <i class="m-icon-filter text-[15px] mr-[5px]"></i>
                    {{ t("filter") }}
                  </span>

                  <span
                    class="text-brand-headline text-14 underline"
                    @click="showFiltersOnMobile = false"
                    >{{ t("close") }}</span
                  >
                </div>
                <ProductListCurrentRefinements
                  class="max-md:mb-[2px] md:hidden"
                />
                <ProductListSortingPanel
                  class="mb-5"
                  :is-open="true"
                  :sorting="sortingRef"
                  @update:value="changeSorting"
                ></ProductListSortingPanel>
                <ProductListHierarchicalFilter
                  :attribute-name="t('product_list.filters.kategoria')"
                  class="mb-5"
                  :attributes="['v.lvl0', 'v.lvl1', 'v.lvl2', 'v.lvl3']"
                  :is-open="true"
                />
                <ProductListFacetFilter
                  v-if="!hideFacets?.includes('masterproduct_nem_property')"
                  attribute="b"
                  :attribute-name="t('product_list.filters.nem')"
                  sort-by="spec_alphabet"
                  class="mb-5"
                  :is-open="true"
                />
                <ProductListPriceFilter
                  v-if="!hideFacets?.includes('masterproduct_price')"
                  :attribute="priceFieldToUse"
                  :attribute-name="t('product_list.filters.ar')"
                  class="mb-5"
                  :is-open="true"
                  :hide-in-action-filter="
                    filters?.includes('(c.in_action = True)')
                  "
                  :step="1"
                />
                <ProductListFacetFilter
                  v-if="!hideFacets?.includes('product_meret_property')"
                  attribute="e"
                  :attribute-name="t('product_list.filters.meret')"
                  sort-by="spec_meret"
                  class="mb-5"
                  :is-open="true"
                />
                <ProductListFacetFilter
                  v-if="
                    !hideFacets?.includes('masterproduct_gyarto_property') &&
                    themeGroup !== 'tnf'
                  "
                  attribute="a"
                  :attribute-name="t('product_list.filters.gyarto')"
                  class="mb-5"
                  :is-open="true"
                />
                <ProductListPaletteFilter
                  v-if="!hideFacets?.includes('product_paletta_property')"
                  attribute="d"
                  :attribute-name="t('color')"
                  class="mb-5"
                  :is-open="true"
                />
                <ProductListFacetFilter
                  v-if="
                    !hideFacets?.includes(
                      'masterproduct_termekjellemzo_oli_felhasz_property',
                    )
                  "
                  attribute="r_a"
                  :attribute-name="t('product_list.filters.felhasznalas')"
                  class="mb-5"
                  :is-open="true"
                />
                <ProductListFacetFilter
                  v-if="
                    !hideFacets?.includes(
                      'masterproduct_termekjellemzo_oli_tulajd_property',
                    )
                  "
                  attribute="s_a"
                  :attribute-name="t('product_list.filters.tulajdonsag')"
                  class="mb-5"
                  :is-open="false"
                />
                <ProductListFacetFilter
                  v-if="
                    !hideFacets?.includes(
                      'masterproduct_termekjellemzo_anyag_property',
                    )
                  "
                  attribute="f"
                  :attribute-name="t('product_list.filters.anyag')"
                  class="mb-5"
                  :is-open="false"
                />
                <ProductListRangeFilter
                  v-if="
                    !hideFacets?.includes(
                      'masterproduct_termekjellemzo_oli_tomegl_property',
                    )
                  "
                  attribute="n_n"
                  :attribute-name="t('product_list.filters.tomeg')"
                  class="mb-5"
                  data-type="weight"
                  :step="1"
                  :is-open="false"
                />
                <ProductListFacetFilter
                  v-if="
                    !hideFacets?.includes(
                      'masterproduct_termekjellemzo_oli_toltoany_property',
                    )
                  "
                  attribute="j"
                  :attribute-name="t('product_list.filters.toltoanyag')"
                  class="mb-5"
                  :is-open="false"
                />
                <ProductListFacetFilter
                  v-if="
                    !hideFacets?.includes(
                      'masterproduct_termekjellemzo_oli_ujhossz_property',
                    )
                  "
                  attribute="k"
                  :attribute-name="t('product_list.filters.ujjhossz')"
                  class="mb-5"
                  :is-open="false"
                />
                <ProductListFacetFilter
                  v-if="
                    !hideFacets?.includes(
                      'masterproduct_termekjellemzo_oli_talp_property',
                    )
                  "
                  attribute="l"
                  :attribute-name="t('product_list.filters.talp')"
                  class="mb-5"
                  :is-open="false"
                />
                <ProductListRangeFilter
                  v-if="
                    !hideFacets?.includes(
                      'masterproduct_termekjellemzo_oli_urtartalom_property',
                    )
                  "
                  attribute="m_n"
                  :attribute-name="t('product_list.filters.urtartalom')"
                  class="mb-5"
                  data-type="capacity"
                  :step="1"
                  :is-open="false"
                />
                <ProductListFacetFilter
                  v-if="
                    !hideFacets?.includes(
                      'masterproduct_termekjellemzo_oli_ferohelyl_property',
                    )
                  "
                  attribute="o"
                  :attribute-name="t('product_list.filters.ferohelyl')"
                  sort-by="spec_alphabet"
                  class="mb-5"
                  :is-open="false"
                />
                <ProductListFacetFilter
                  v-if="
                    !hideFacets?.includes(
                      'masterproduct_termekjellemzo_oli_ferohely_property',
                    )
                  "
                  attribute="q"
                  :attribute-name="t('product_list.filters.ferohely')"
                  sort-by="spec_alphabet"
                  class="mb-5"
                  :is-open="false"
                />
                <ProductListRangeFilter
                  v-if="
                    !hideFacets?.includes(
                      'masterproduct_termekjellemzo_oli_konflimit_property',
                    )
                  "
                  attribute="p_n"
                  :attribute-name="t('product_list.filters.konflimit')"
                  class="mb-5"
                  data-type="celsius"
                  :step="1"
                  :is-open="false"
                />
                <ProductListFacetFilter
                  v-if="
                    !hideFacets?.includes(
                      'masterproduct_termekjellemzo_oli_bejarat_property',
                    )
                  "
                  attribute="t"
                  :attribute-name="t('product_list.filters.bejarat')"
                  class="mb-5"
                  :is-open="false"
                />
                <ProductListFacetFilter
                  v-if="
                    !hideFacets?.includes(
                      'masterproduct_termekjellemzo_oli_rudazat_property',
                    )
                  "
                  attribute="i"
                  :attribute-name="t('product_list.filters.rudazat')"
                  class="mb-5"
                  :is-open="false"
                />
                <ProductListFacetFilter
                  v-if="
                    !hideFacets?.includes(
                      'masterproduct_termekjellemzo_oli_atmero_property',
                    )
                  "
                  attribute="w"
                  :attribute-name="t('product_list.filters.atmero')"
                  class="mb-5"
                  sort-by="spec_atmero"
                  :is-open="false"
                />
                <ProductListFacetFilter
                  v-if="
                    !hideFacets?.includes(
                      'masterproduct_termekjellemzo_oli_testmagas_property',
                    )
                  "
                  attribute="x"
                  :attribute-name="t('product_list.filters.testmagas')"
                  sort-by="spec_alphabet"
                  class="mb-5"
                  :is-open="false"
                />
                <ProductListFacetFilter
                  v-if="
                    !hideFacets?.includes(
                      'masterproduct_termekjellemzo_oli_karabjel_property',
                    )
                  "
                  attribute="y_a"
                  :attribute-name="t('product_list.filters.karabjel')"
                  class="mb-5"
                  :is-open="false"
                />
                <!--
                <ProductListFacetFilter
                  v-if="!hideFacets?.includes('available_in_stores')"
                  attribute="u"
                  :attribute-name="t('product_list.filters.stock')"
                  sort-by="spec_alphabet"
                  class="mb-5"
                  :is-open="false"
                />
                -->
              </div>
            </div>
            <div class="col-span-12 md:col-span-9 xl:col-span-10">
              <div class="results">
                <div class="results-meta">
                  <!-- <ProductListSearchStats /> -->
                  <!-- <MeiliSearchSorting /> -->
                </div>
                <ProductListSearchResults
                  ref="searchResults"
                  @update="handleSearchResultsUpdate"
                  @has-items="hasResults = true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ProductListFilterButton
        :filters-visible="showFiltersOnMobile"
        result-type="product_list.hits"
        @toggle="showFiltersOnMobile = !showFiltersOnMobile"
      ></ProductListFilterButton>
    </ProductListSearchProvider>

    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="isRealContent(afterContent)"
      class="max-w-[1000px] mx-auto px-20"
      v-html="afterContent"
    ></div>
    <!--eslint-enable-->
  </div>
</template>

<style scoped lang="scss">
$menu-height: 56px;
$filter-button-height: 44px;
$filter-button-bottom: 50px;

@media (max-width: 767px) {
  #productListFilters {
    top: $menu-height;
    height: calc(100vh - $menu-height);
    padding-bottom: calc($filter-button-height + $filter-button-bottom);

    &.ios {
      padding-bottom: calc(
        $filter-button-height + $filter-button-bottom + 80px
      );
    }
  }
}
</style>
