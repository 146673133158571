<script lang="ts" setup>
import {
  AisCurrentRefinements,
  AisClearRefinements,
} from "vue-instantsearch/vue3/es";
import { useProductListStore } from "~/stores/main";
import { usePriceFormatter } from "~/utils/priceFormatter";

const store = useProductListStore();
const { t } = useI18n();

const refineItem = (item: any, refinement: any) => {
  // console.log('refineItem', item, refinement);

  // item.refinements contains the refinements for item.attribute
  // refinement contains the removed option
  // we need to calculate the new pallette refinements and update the store

  if (refinement.attribute === "product_paletta_property") {
    if (!store.paletteFilters.get(window.location.pathname))
      store.paletteFilters.set(window.location.pathname, []);

    const arrayOfPalettes: string[] = [];
    for (const element of item.refinements) {
      // product_paletta_propertg
      if (element.attribute === "d") arrayOfPalettes.push(element.value);
    }
    // console.log('arrayOfPalettes', arrayOfPalettes);

    const arrayToUpdate = arrayOfPalettes.filter(
      (value) => value !== refinement.value,
    );
    // console.log('arrayToUpdate', arrayToUpdate);
    store.paletteFilters.set(window.location.pathname, arrayToUpdate);
  }
  item.refine(refinement);
};

const refinementLabel = (refinement: Record<string, any>) => {
  if (refinement.attribute === "d")
    return useNuxtApp().$i18n.t("palette." + refinement.label);
  if (refinement.attribute === "c.in_action")
    return useNuxtApp().$i18n.t("in_action_products");
  if (["h.price_min", "c.price_min"].includes(refinement.attribute)) {
    return (
      refinement.label.substring(0, 2) +
      usePriceFormatter(parseInt(refinement.label.substring(2)))
    );
  }
  if (refinement.attribute === "n_n")
    return (
      refinement.label.substring(0, 2) +
      parseInt(refinement.label.substring(2)) / 1000 +
      " kg"
    );
  if (refinement.attribute === "m_n") return refinement.label + " liter";
  if (refinement.attribute === "p_n") return refinement.label + " °C";
  return refinement.label;
};
</script>

<template>
  <ais-current-refinements v-slot="{ items, createURL }" class="flex flex-wrap">
    <!-- {{ item.label }}: -->
    <template v-for="item in items" :key="item.attribute">
      <span
        v-for="refinement in item.refinements"
        :key="
          [
            refinement.attribute,
            refinement.type,
            refinement.value,
            refinement.operator,
          ].join(':')
        "
      >
        <a
          :href="createURL(refinement)"
          @click.prevent="refineItem(item, refinement)"
        >
          <span
            class="inline-flex items-center gap-1.5 py-0 md:py-1.5 pl-[5px] pr-px md:pl-3 md:pr-2 rounded-full text-10 md:text-xs font-medium bg-brand-secondary mr-2.5 mb-2 text-brand-body"
          >
            {{ refinementLabel(refinement) }}
            <button
              type="button"
              :aria-label="refinementLabel(refinement)"
              class="flex-shrink-0 h-4 w-4 inline-flex items-center justify-center rounded-full text-black hover:bg-secondary00 hover:text-surface00 focus:outline-none focus:bg-secondary00 focus:text-surface00"
            >
              <span class="sr-only">{{ t("filter_delete") }}</span>
              <svg
                class="h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </button>
          </span>
        </a>
      </span>
    </template>
    <ais-clear-refinements>
      <template #default="{ canRefine, refine, createURL: createClearURL }">
        <a
          v-if="canRefine"
          :href="createClearURL()"
          class="!text-brand-headline text-12 underline ml-[16px]"
          @click.prevent="refine"
        >
          {{ t("clear_filters") }}
        </a>
      </template>
    </ais-clear-refinements>
  </ais-current-refinements>
</template>

<style scoped>
.ais-CurrentRefinements--noRefinement .ais-ClearRefinements {
  display: none;
}
</style>
