<script lang="ts" setup>
import { AisStats } from "vue-instantsearch/vue3/es";

const emit = defineEmits(["hits-number-update"]);

const handleHitsNumberUpdate = (hitsNumber) => {
  emit("hits-number-update", hitsNumber);
};
</script>

<template>
  <ais-stats>
    <template #default="{ nbHits: hitsNumber /*, query */ }">
      {{ handleHitsNumberUpdate(hitsNumber) }}
      <!--
      <BaseText v-if="hitsNumber" size="m" class="">
        ({{ t("product_list.hits", { hits: hitsNumber }) }})
      </BaseText>
      <div v-else></div>
      -->
    </template>
  </ais-stats>
</template>
