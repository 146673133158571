<script lang="ts" setup>
import { AisHits } from "vue-instantsearch/vue3/es";
import { ref, watch } from "vue";
import { useProductListStore } from "~/stores/main";
import type { MasterProduct } from "~/types/common";

const emit = defineEmits(["update", "hasItems"]);
const lastRefinedPalette = ref("");
const store = useProductListStore();

watch(
  () => JSON.stringify(store.paletteFilters?.get(window.location.pathname)),
  () => {
    // console.log(`Value for key "${window.location.pathname}" changed:`, newValue);
    const paletteFilters = store.paletteFilters.get(window.location.pathname);
    lastRefinedPalette.value = paletteFilters.length
      ? paletteFilters[paletteFilters.length - 1]
      : "";
  },
);

let lastChange = null;
const handleItems = (products: MasterProduct[]) => {
  nextTick(() => {
    // Emit "hasItems" immediately when items are rendered
    if (products.length > 0) {
      emit("hasItems");
    }

    // Debounced "update" event
    lastChange = Date.now();
    const timeoutId = setTimeout(() => {
      if (Date.now() - lastChange < 500) {
        clearTimeout(timeoutId);

        return;
      }

      emit("update", products);
    }, 500);
  });
};
</script>

<template>
  <AisHits>
    <template #default="{ items }">
      <div v-if="items.length" class="mb-5 rounded-16">
        <div
          class="grid gap-20 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-2 mb-5"
        >
          <div v-for="product in items" :key="product.id" class="">
            <ProductListProductCard
              class="h-full"
              :product="product"
              :palette="lastRefinedPalette"
            />
          </div>
        </div>
        <ProductListPagination class="pt-3" />
      </div>
      {{ handleItems(items) }}
    </template>
  </AisHits>
</template>
