<script lang="ts" setup>
import * as Sentry from "@sentry/vue";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { AisInstantSearch } from "vue-instantsearch/vue3/es";
import { history } from "instantsearch.js/es/lib/routers";
import { useFilterRegistry } from "~/stores/filterRegistry";
import { useMeilisearchIndex } from "~/utils/meilisearch";

// -----------------------
// props & emits
// -----------------------
const props = withDefaults(
  defineProps<{
    sorting: string;
  }>(),
  {
    sorting: "sku:desc",
  },
);

// -----------------------
// composables
// -----------------------
const config = useRuntimeConfig();
const { getActiveFilters, hasActiveFilter } = useHelpers();
const filterRegistry = useFilterRegistry();

// -----------------------
// reactive properties
// -----------------------
const masterProductsIndex = config?.public?.meilisearchIndexMasterproducts;
const indexName = `${masterProductsIndex}:${props.sorting}`;
const taxonMapping = ref({});
const allTaxonFetched = ref(false);
const valueSeparator = "__";
const rangeSeperator = "-";
const useMapping = config.public.taxaIdAsFilterValue === 1;

filterRegistry.filters = filterRegistry.productListFilters;

const routingRef = ref({
  router: history({
    cleanUrlOnDispose: true,
  }),
  stateMapping: {
    stateToRoute(uiState) {
      if (!uiState[indexName]) {
        return {};
      }

      const state = uiState[indexName];
      const result = {};

      if (state.page && state.page !== 1) {
        result.page = state.page;
      }

      if (props.sorting !== "sku:desc") {
        result.sorting = props.sorting;
      }

      if ("range" in state) {
        for (const attribute in state.range) {
          result[attribute] = state.range[attribute].replace(
            ":",
            rangeSeperator,
          );
        }
      }

      if ("refinementList" in state) {
        for (const attribute in state.refinementList) {
          const taxonomy = filterRegistry.getTaxonomy(attribute);
          const values = [];

          if (useMapping && taxonomy) {
            for (const taxa of state.refinementList[attribute]) {
              if (taxonomy in taxonMapping.value) {
                if (taxa in taxonMapping.value[taxonomy]) {
                  values.push(taxonMapping.value[taxonomy][taxa]);
                } else {
                  Sentry.captureEvent({
                    message: `Taxa name (${taxa}) was not found in mapping`,
                    level: "error",
                    extra: {
                      mapping: taxonMapping.value,
                    },
                  });
                }
              } else {
                Sentry.captureEvent({
                  message: `Taxonomy (${taxonomy}) was not found in mapping`,
                  level: "error",
                  extra: {
                    mapping: taxonMapping.value,
                  },
                });
              }
            }
          } else {
            values.push(...state.refinementList[attribute]);
          }

          result[attribute] = values.join(valueSeparator);
        }
      }

      if ("hierarchicalMenu" in state) {
        for (const attribute in state.hierarchicalMenu) {
          result[attribute] =
            state.hierarchicalMenu[attribute].join(valueSeparator);
        }
      }

      return result;
    },
    routeToState(routeState) {
      const result = {
        [indexName]: {
          page: routeState.page ?? 1,
          query: routeState.query ?? null,
        },
      };

      for (const key in routeState) {
        const filter = filterRegistry.getFilterByAttribute(key);

        if (!filter) {
          continue;
        }

        if (filter.filterType === "hierarchical") {
          if (!result[indexName].hierarchicalMenu) {
            result[indexName].hierarchicalMenu = {};
          }

          result[indexName].hierarchicalMenu[key] =
            routeState[key].split(valueSeparator);
        }

        if (filter.filterType === "facet") {
          let values = routeState[key].split(valueSeparator);

          // Need to map taxa ID to name
          if (useMapping && "taxonomy" in filter) {
            if (filter.taxonomy in taxonMapping.value) {
              const taxon = taxonMapping.value[filter.taxonomy];
              values = values
                .map((value) => {
                  const name = Object.keys(taxon).find(
                    (name) => taxon[name] === parseInt(value),
                  );

                  if (name) {
                    return name;
                  } else {
                    Sentry.captureEvent({
                      message: `Taxa (name) was not found by ID (${value})`,
                      level: "error",
                      extra: {
                        mapping: taxonMapping.value,
                      },
                    });

                    return null;
                  }
                })
                .filter((value) => value !== null);
            } else {
              Sentry.captureEvent({
                message: `Taxonomy (${filter.taxonomy}) was not found in mapping`,
                level: "error",
                extra: {
                  mapping: taxonMapping.value,
                },
              });
            }
          }

          if (!result[indexName].refinementList) {
            result[indexName].refinementList = {};
          }

          result[indexName].refinementList[key] = values;
        }

        if (filter.filterType === "range") {
          if (!result[indexName].range) {
            result[indexName].range = {};
          }

          result[indexName].range[key] = routeState[key].replace(
            rangeSeperator,
            ":",
          );
        }
      }

      return result;
    },
  },
});

const host = config.public.meilisearchHost;
const searchApiKey = config.public.meilisearchKey;
const options = { finitePagination: true, primaryKey: "id" };

const { searchClient } = instantMeiliSearch(host, searchApiKey, options);

// -----------------------
// helper functions
// -----------------------
const updateTaxaMapping = (items: Record<string, any>[]) => {
  for (const item of items) {
    const externalKey = item.taxonomy_external_key;

    if (!(externalKey in taxonMapping.value)) {
      taxonMapping.value[externalKey] = {};
    }

    taxonMapping.value[externalKey][item.name] = item.id;
  }
};

const onStateChange = ({ uiState, setUiState }) => {
  if (allTaxonFetched.value) {
    setUiState(uiState);
    return;
  }

  if (indexName in uiState) {
    const refinementList = uiState[indexName].refinementList ?? {};

    if (!useMapping) {
      setUiState(uiState);
      return;
    }

    const taxonomies = Object.keys(refinementList).filter((attribute) => {
      return filterRegistry.getTaxonomy(attribute) !== null;
    });

    // Fetch taxon only if there is at least one taxonomy-based filter
    if (taxonomies.length === 0) {
      setUiState(uiState);
      return;
    }
  }

  taxaIndex
    .search("", {
      filter: `taxonomy_external_key IN [${filterRegistry.taxonomies.join(", ")}]`,
      limit: 1000,
      attributesToRetrieve: ["id", "name", "taxonomy_external_key"],
    })
    .then((response) => {
      if (response.hits) {
        updateTaxaMapping(response.hits);
        allTaxonFetched.value = true;
        setUiState(uiState);
      } else {
        Sentry.captureEvent({
          message: `Property "hits" missing from MeiliSearch response`,
          level: "error",
          extra: {
            response,
          },
        });
      }
    })
    .catch((exception) => {
      Sentry.captureEvent({
        message: `Failed to fetch taxon to build taxa mapping`,
        level: "error",
        extra: {
          exception,
        },
      });
    });
};

// ----------------------------
// load active filters' taxon
// ----------------------------
const taxaIndex = useMeilisearchIndex(config.public.meilisearchIndexTaxa);

if (useMapping && hasActiveFilter()) {
  const meilisearchFilter = [];
  const activeFilters = getActiveFilters();

  for (const attribute in activeFilters) {
    const taxonomy = filterRegistry.getTaxonomy(attribute);
    const ids = activeFilters[attribute].split(valueSeparator);

    if (taxonomy) {
      meilisearchFilter.push(
        `(taxonomy_external_key = ${taxonomy} AND id IN [${ids.join(", ")}])`,
      );
    }
  }

  const result = await taxaIndex.search("", {
    filter: meilisearchFilter.join(" OR "),
    limit: 1000,
    attributesToRetrieve: ["id", "name", "taxonomy_external_key"],
  });

  updateTaxaMapping(result.hits);
}
</script>

<template>
  <AisInstantSearch
    :routing="routingRef"
    :index-name="`${config.public.meilisearchIndexMasterproducts}:${props.sorting}`"
    :search-client="searchClient"
    :on-state-change="onStateChange"
    :future="{
      preserveSharedStateOnUnmount: false,
      persistHierarchicalRootCount: false,
    }"
  >
    <slot name="default" />
  </AisInstantSearch>
</template>
