<script lang="ts" setup>
// -----------------------
// props & emits
// -----------------------
const props = withDefaults(
  defineProps<{
    options?: Record<string, string>[];
    isOpen?: boolean;
    sorting?: string;
  }>(),
  {
    options: () => {
      const { t } = useI18n();

      return [
        {
          label: t("product_list.sort_price:desc"),
          value: "c.price_min:desc",
        },
        {
          label: t("product_list.sort_price:asc"),
          value: "c.price_min:asc",
        },
        {
          label: t("product_list.sort_sku:desc"),
          value: "sku:desc",
        },
        {
          label: t("product_list.sort_full_name:asc"),
          value: "full_name:asc",
        },
        {
          label: t("product_list.sort_full_name:desc"),
          value: "full_name:desc",
        },
      ];
    },
    isOpen: true,
    sorting: "",
  },
);

const emit = defineEmits<{
  (e: "update:value", newSorting: string): void;
}>();

// -----------------------
// refs
// -----------------------
const isOpen = props.isOpen ? ref(true) : ref(false);
const sorting = ref(props.sorting ?? null);

// -----------------------
// vue events
// -----------------------
watch(sorting, (value) => {
  emit("update:value", value);
});

watch(
  () => props.sorting,
  (value) => {
    sorting.value = value;
  },
);
</script>

<template>
  <div class="bg-white rounded-16 p-4 text-15">
    <div
      class="flex flex-row cursor-pointer"
      @mousedown.prevent="isOpen = !isOpen"
    >
      <div class="basis-5/6 font-bold text-xl flex flex-row font-lora">
        <span>Rendezés</span>
      </div>
      <div class="basis-1/6 text-right">
        <ProductListFilterArrow :inverted="isOpen" />
      </div>
    </div>

    <div class="pt-3" :class="{ hidden: !isOpen }">
      <BaseCheckbox
        v-for="option in options"
        :key="option.value"
        :value="sorting === option.value"
        :disabled="sorting === option.value"
        :label="option.label"
        :name="option.value"
        @change="sorting = option.value"
      >
        <BaseText tag="span">{{ option.label }}</BaseText>
      </BaseCheckbox>
    </div>
  </div>
</template>
