<script lang="ts" setup>
import { AisClearRefinements, AisStats } from "vue-instantsearch/vue3/es";

const props = defineProps({
  filtersVisible: {
    type: Boolean,
    required: true,
  },
  resultType: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();

// -----------------------
// props & emits
// -----------------------
defineEmits<{
  (e: "toggle"): void;
}>();

// -----------------------
// refs
// -----------------------
const isListRefined = ref(false);
const hitsNumber = ref(0);

// -----------------------
// computed properties
// -----------------------
const showHitsNumber = computed(() => {
  return props.filtersVisible && isListRefined.value;
});

// -----------------------
// helper methods
// -----------------------
const handleRefinedStatusChange = (value) => {
  isListRefined.value = value;
};

const handleHitsNumberChange = (value) => {
  hitsNumber.value = value;
};

// -----------------------
// vue events
// -----------------------
watch(
  () => props.filtersVisible,
  (visible) => {
    if (visible) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  },
);
</script>

<template>
  <BaseButton
    class="filter-button md:hidden bg-brand-search fixed w-[180px] h-[44px] left-1/2 bottom-[50px] ml-[-90px] rounded-30 z-productlistfilter"
    :shadow-outside="true"
    @click="$emit('toggle')"
  >
    <ais-clear-refinements class="hidden">
      <template #default="{ canRefine }">
        {{ handleRefinedStatusChange(canRefine) }}
      </template>
    </ais-clear-refinements>

    <ais-stats class="hidden">
      <template #default="{ nbHits }">
        {{ handleHitsNumberChange(nbHits) }}
      </template>
    </ais-stats>

    <template v-if="showHitsNumber">
      {{ t(resultType, { hits: hitsNumber }) }}
    </template>

    <template v-else>
      <i class="m-icon-filter text-[15px] mr-[5px]"></i>
      {{ t("filter") }}
    </template>
  </BaseButton>
</template>
